import React from "react"
import Layout from "../components/LayoutComponent"
import Seo from "../components/SeoComponent"
import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl"
import "../assets/contact.css"

const Contact = () => {
  const intl = useIntl()
  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: "kontakt" })}
        canonicalUrl={"contact"}
      />
      <div className="contact-container">
        <section className="contact">
          <div className="contact-text">
            {/* contact */}
            <p>
              <FormattedMessage id="contact.contact-one" />
            </p>
            <p>
              <FormattedMessage id="contact.contact-two" />
            </p>
            <p>
              <FormattedMessage id="contact.contact-three" />
            </p>
          </div>
          <div className="contact-form">
            <form
              action="https://formsubmit.co/perina.kulic@gmail.com"
              class="form"
              method="POST"
            >
              <h5>
                <FormattedMessage id="contact.request" />
              </h5>
              <div class="form-row">
                <input type="hidden" name="_template" value="box" />
                <input
                  type="hidden"
                  name="_next"
                  value="https://perinakulic.netlify.app/thanks"
                />
                <label htmlFor="name" class="form-label">
                  <FormattedMessage id="contact.name" />
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  class="form-input"
                  required
                />
              </div>
              <div class="form-row">
                <label htmlFor="phone" class="form-label">
                  <FormattedMessage id="contact.phone" />
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="tel"
                  class="form-input"
                  required
                />
              </div>
              <div class="form-row">
                <label htmlFor="email" class="form-label">
                  <FormattedMessage id="contact.email" />
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  class="form-input"
                  required
                />
              </div>
              <div class="form-row">
                <label htmlFor="texarea" class="form-label">
                  <FormattedMessage id="contact.msg" />
                </label>
                <textarea
                  class="form-textarea"
                  name="message"
                  required
                ></textarea>
                <button type="submit" class="btn btn-block">
                  <FormattedMessage id="contact.send" />
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Contact
